import api from "../../utils/api";
import {getTenant} from "../helpers";

export function getGradesPerCourseAPI(additionalData={}, callback) {
    api.iblaxdApiCallWrapper(
        (args) => api.iblaxdperformance.performanceOrgsGradingPerCourseRetrieve(...args),
        getTenant(),
        { ...additionalData }
    )((err, data, response) => (err, callback(data), response));
}

export function getAverageCourseGradeAPI(callback) {
    api.iblaxdApiCallWrapper(
        (args) => api.iblaxdperformance.performanceOrgsGradingAverageRetrieve(...args),
        getTenant(),
        {}
    )((err, data, response) => (err, callback(data), response));
}
