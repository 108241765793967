import IblJsClient from '@iblai/ibl-web-core-frontend';

const api = new IblJsClient({
    IBL_API_PROXY_URL: process.env.REACT_APP_IBL_WEB_URL,
    IBL_LMS_URL: process.env.REACT_APP_IBL_LMS_URL,
});
api.initiateAxdClientsWithAxdWebUrl(process.env.REACT_APP_IBL_AXD_URL);
api.isDirectAxdAuth = process.env.REACT_APP_IBL_IS_DIRECT_AXD_AUTH === 'true';
api.spaLoginPath = process.env.REACT_APP_IBL_SPA_LOGIN_PATH;
export default api;
